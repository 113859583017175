import React from 'react'
import tw from 'twin.macro'

import Img from 'gatsby-image'

import { Text } from './core/typography'
import Date from './Date'

const EventCard = ({ className, image, title, startDate }) => (
  <div className={className} tw="overflow-hidden bg-ui-03 rounded">
    <div>
      <Img fluid={image.childImageSharp.fluid} alt={title}></Img>
    </div>
    <div tw="flex flex-col pl-4 pb-4 pr-8">
      <div tw="relative">
        <Date tw="absolute -top-4 right-0" value={startDate} />
      </div>
      <Text tw="mt-20 text-xl font-bold">{title}</Text>
    </div>
  </div>
)

export default EventCard
