import React from 'react'
import tw from 'twin.macro'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import { H1 } from '../components/core/typography'
import { Container } from '../components/core/container'
import EventCard from '../components/EventCard'
import SEO from '../components/SEO'

const ContentWrapper = tw(Container)`
  my-24
  last:(mb-0)
  max-w-screen-lg
`

export const EventsIndexPageTemplate = ({ events, title, heading }) => (
  <div>
    <SEO title={title}></SEO>
    <ContentWrapper tw="text-center">
      <H1>{heading}</H1>
    </ContentWrapper>
    <div tw="pb-24 relative before:(content absolute bg-ui-01 inset-x-0 top-16 bottom-0 -z-10)">
      <ContentWrapper>
        <div tw="grid grid-cols-6 gap-4">
          {events &&
            events.map((event) => (
              <div tw="col-span-6 sm:(col-span-3) lg:(col-span-2)">
                <Link to={event.slug}>
                  <EventCard
                    image={event.frontmatter.coverImage}
                    title={event.frontmatter.title}
                    startDate={event.frontmatter.startDate}
                  />
                </Link>
              </div>
            ))}
        </div>
      </ContentWrapper>
    </div>
  </div>
)

const EventsIndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  const events = data.events.edges.map((edge) => ({
    frontmatter: edge.node.frontmatter,
    slug: edge.node.fields.slug
  }))

  return (
    <Layout>
      <EventsIndexPageTemplate
        events={events}
        title={frontmatter.title}
        heading={frontmatter.heading}
      />
    </Layout>
  )
}

export default EventsIndexPage

export const pageQuety = graphql`
  query Events {
    markdownRemark(frontmatter: { templateKey: { eq: "events-index-page" } }) {
      frontmatter {
        title
        heading
      }
    }

    events: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "event-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            coverImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            startDate
          }
        }
      }
    }
  }
`
