import React from 'react'
import tw from 'twin.macro'
import moment from 'moment'

const DatePart = tw.span`
  block
  text-text-04
`

const Day = tw(DatePart)`
  text-sm
  font-semibold`

const Month = tw(DatePart)`
  text-sm
  font-semibold`

const Year = tw(DatePart)`
  text-xs
  text-decorative-02`

const DateDetail = tw.div`
  bg-ui-05
  rounded
  flex
  flex-col
  justify-center
  items-center
  w-16
  h-20`

const Date = ({ className, value }) => {
  const date = moment(value).locale('nl').local()

  return (
    <DateDetail className={className}>
      <Day>{date.format('DD')}</Day>
      <Month>{date.format('MMM').toUpperCase()}</Month>
      <Year>{date.format('YYYY')}</Year>
    </DateDetail>
  )
}

export default Date
